<template>
	<div class="w-full">
		<div class="flex justify-between items-center">
			<h1 class="text-xl font-extrabold sm:text-2xl">Результаты независимой оценки за 1-3 года</h1>
			<div class="flex items-center">
				<div class="block-check">
					<span class="check-text" @click="openCheks = !openCheks">{{ params.columns.join('/') }}</span>
					<div v-if="openCheks" class="dropList">
						<p>
							<span>Выберите года</span><br />
							не более трёх
						</p>
						<ul>
							<li v-for="item in yearArr" :key="item.year">
								<input @change="changeCheckBox()" type="checkbox" :disabled="item.disabled" name="label" :id="item.year" v-model="params.columns" :value="item.year" /><label
									:for="item.year"
									>{{ item.year }}</label
								>
							</li>
						</ul>
					</div>
				</div>
				<Button text="Экспорт в Excel" icon="exel" @click.native="upLoadExcel(params)" :disabled="loadToExcell" :load="loadToExcell" />
			</div>
		</div>
		<div class="table-respons">
			<table :style="includedMO.length > 0 ? 'min-height: 450px' : ''">
				<thead>
					<tr>
						<th rowspan="3" class="th-sort">
							<span class="cursor-pointer" @click="switchFilterVisibility(0)">Наименование МО</span>
							<FilterComponent
								v-on:change="switchFilterVisibility(null)"
								:filterOpen="filterOpen"
								position="left"
								:id-filter="0"
								:param="params"
								@query="getData"
								:fields="firstField"
								:item="firstField[0]"
							/>
						</th>
						<th colspan="15" class="text-center"><p>Результаты НОК</p></th>
					</tr>
					<tr>
						<th :colspan="params.columns.length" class="text-center" v-for="(item, index) in fields" :key="'H-' + index">
							<p>{{ item.Name }}</p>
						</th>
					</tr>
					<tr>
						<th v-for="(item, index) in lastFields" :key="'H1-' + index">
							<p class="cursor-pointer underline" @click="switchFilterVisibilityTwo(index)">{{ item.Name }}</p>
							<FilterComponent
								v-on:change="switchFilterVisibility(null)"
								:filterOpen="filterOpenTwo"
								position="right"
								:id-filter="index"
								:param="params"
								@query="getData"
								:fields="lastFields"
								:item="item"
							/>
						</th>
					</tr>
				</thead>
				<tbody v-for="(item, i) in includedMO" :key="'R-' + i">
					<tr @click="openRow(item)" :class="{ 'bg-gray-30': item.medicalorgid === opened, 'cursor-pointer': item.hasbranch }">
						<td>
							<p :class="{ underline: item.hasbranch }">{{ item.name }}</p>
						</td>
						<td v-for="(key, index) in lastFields" :key="'D-' + index">
							<p>{{ item[key.fieldName] }}</p>
						</td>
					</tr>
					<template v-if="item.medicalorgid === opened">
						<tr v-for="(subitem, j) in item.branches" :key="'B-' + j" :class="{ 'bg-gray-30': item.medicalorgid === opened }">
							<td>
								<p class="filial" v-if="subitem.isbranch">Филиал</p>
								<p class="filial" v-if="subitem.isclone">Вторичная МО</p>
								<p>{{ subitem.name }}</p>
							</td>
							<td v-for="(key, index) in lastFields" :key="'S-' + index">
								<p>{{ subitem[key.fieldName] }}</p>
							</td>
						</tr>
					</template>
				</tbody>
			</table>
			<center v-if="!loadingTable && includedMO.length === 0" class="w-full py-28 whitespace-no-wrap text-xl leading-5 text-gray-500 text-center">Данных нет...</center>
			<div v-if="loadingTable" class="absolute w-full h-full preloader rounded-lg">
				<loadingComponent class="absolute top-1/2 left-1/2" />
			</div>
		</div>
		<Pagination :totalCount="totalCount" :params="params" @query="getData" />
	</div>
</template>

<script>
import Button from '@/components/Button.vue';
import FilterComponent from '@/components/Filter.vue';
import Pagination from '@/components/Pagination.vue';
// Глобальные данные стора
import { getters, mutations, methods } from '@/store/store';
// import _ from 'lodash';
// Локальные данные стора
import actions from '../store/actions';
import { gettersLocaly } from '../store/getters';
import loadingComponent from '@/components/Loading.vue';

export default {
	name: 'TableIndependentResult',
	components: {
		loadingComponent,
		Button,
		FilterComponent,
		Pagination
	},
	data() {
		return {
			rowsVisible: 5,
			totalCount: 0,
			openDelet: false,
			openCheks: false,
			open: false,
			filterOpen: null,
			filterOpenTwo: null,
			popupTitle: '',
			yearArr: [],
			params: {
				filters: [
					{
						fieldName: 'RegionId',
						filterType: 1,
						filterValueType: 1,
						value1: ''
					},
					{
						fieldName: 'isMain',
						filterType: 1,
						filterValueType: 1,
						value1: 'true'
					}
				],
				sortOption: {
					fieldName: '',
					sortType: null
				},
				columns: ['2020', '2021', '2022'],
				pageSize: 5,
				currentPage: 0
			},
			firstField: [
				{
					Name: 'Наименование МО',
					fieldName: 'name',
					filterType: 2,
					filterValueType: 1,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию (А-Я)',
					label2: 'По убыванию (Я-А)'
				}
			],
			lastFields: [],
			first: true,
			fields: [
				{
					Name: 'Итоговые результаты',
					fieldName: 'totalres',
					filterType: 2,
					filterValueType: 2,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: 'Амбулаторные',
					fieldName: 'ambulatorres',
					filterType: 2,
					filterValueType: 2,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: 'Стационарные',
					fieldName: 'stacionarres',
					filterType: 2,
					filterValueType: 2,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: 'Санаторно-курортные',
					fieldName: 'sanatoriatres',
					filterType: 2,
					filterValueType: 2,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: 'Психиатрические',
					fieldName: 'psychiatricres',
					filterType: 2,
					filterValueType: 2,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				}
			],
			includedMO: [],
			opened: null,
			loadToExcell: false,
			loadingTable: false
		};
	},
	computed: {
		...getters,
		...gettersLocaly
	},
	methods: {
		...mutations,
		...actions,
		...methods,
		openRow(item) {
			if (item.hasbranch) {
				if (this.opened === item.medicalorgid) {
					this.opened = null;
				} else {
					this.opened = item.medicalorgid;
				}
			}
		},
		changeCheckBox() {
			if (this.params.columns.length === 3) {
				this.yearArr.map((item) => {
					let disabled = this.params.columns.some((year) => year === item.year);
					if (!disabled) {
						item.disabled = true;
					} else {
						item.disabled = false;
					}
					return item;
				});
			} else if (this.params.columns.length === 1) {
				this.yearArr.map((item) => {
					let disabled = this.params.columns.some((year) => year === item.year);
					if (disabled) {
						item.disabled = true;
					} else {
						item.disabled = false;
					}
					return item;
				});
			} else {
				this.yearArr.map((item) => {
					item.disabled = false;
					return item;
				});
			}
			this.getData(this.params, false);
		},
		upLoadExcel(params) {
			this.loadToExcell = true;
			this.$http
				.post('MedicalOrgs/GetResultForThreeYearExcel', params, {
					responseType: 'blob',
					headers: {
						'Access-Control-Expose-Headers': 'Content-Disposition'
					}
				})
				.then((res) => {
					let fileNameAndType = decodeURI(`${this.regionName}_Результаты независимой оценки.xlsx`);

					const url = window.URL.createObjectURL(new Blob([res.data]));
					const link = document.createElement('a');
					link.href = url;
					link.setAttribute('download', fileNameAndType);
					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);
					this.loadToExcell = false;
				});
		},
		switchFilterVisibility(key) {
			this.filterOpen = this.filterOpen === key ? null : key;
			this.filterOpenTwo = null;
		},
		switchFilterVisibilityTwo(key) {
			this.filterOpenTwo = this.filterOpenTwo === key ? null : key;
			this.filterOpen = null;
		},
		getData(params, flag) {
			this.loadingTable = true;
			// если фильтруем/сортируем то возвращаем пагинацию на 1 страницу
			if (flag) {
				this.params.currentPage = 0;
			}

			this.getResultForThreeYear(params).then((res) => {
				this.includedMO = res.data.data;
				this.totalCount = Math.ceil(res.data.totalRecords / this.params.pageSize);
				this.loadingTable = false;
				this.lastFields = [];
				this.fields.forEach((item) => {
					this.yearArr.forEach((year) => {
						let FN = `${item.fieldName}_${year.year}`,
							name = year.year,
							SB = null,
							val1 = '',
							val2 = null;

						if (params.sortOption.sortType && params.sortOption.fieldName === FN) {
							SB = params.sortOption.sortType;
						}

						if (params.filters.length > 2) {
							for (let i = 0; i < params.filters.length; i += 1) {
								if (params.filters[i].fieldName === FN) {
									val1 = params.filters[i].value1;
									if (params.filters[i].value2) val2 = params.filters[i].value2;
								}
							}
						}

						let isYear = params.columns.some((key) => {
							return key === year.year;
						});
						if (isYear) {
							this.lastFields.push({
								Name: name,
								fieldName: FN,
								filterType: 1,
								filterValueType: 2,
								value1: val1,
								value2: val2,
								sortBy: SB,
								label1: 'По возрастанию',
								label2: 'По убыванию'
							});
						}
					});
				});
			});
		}
	},
	mounted() {
		let region = this.userIsInRoles(['Support', 'MZ_Employee', 'MZ_Observer']) ? this.$route.query.regionId : this.getAuthUser.regionId;
		this.params.filters[0].value1 = region;
		this.getRegionYearData(region).then((res) => {
			this.yearArr = [];
			this.params.columns = [];
			res.data.availableYears.forEach((item) => {
				this.yearArr.push({
					year: item.toString(),
					disabled: null
				});
				if (this.params.columns.length < 3) {
					this.params.columns.push(item.toString());
				}
			});
			this.changeCheckBox();
		});
	}
};
</script>

<style scoped>
.check-text {
	width: 100%;
	height: 100%;
	line-height: 44px;
}
.block-check {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 0px 20px;
	gap: 4px;
	width: 209px;
	height: 44px;
	background: #f8f9fd;
	border: 2px solid #3377ff;
	border-radius: 10px;
	position: relative;
	margin-right: 20px;
	font-weight: 700;
	font-size: 15px;
	line-height: 18px;
	color: #3377ff;
}

.block-check .dropList {
	position: absolute;
	right: 0;
	top: 100%;
	z-index: 999;
	padding: 20px;
	background: #fff;
	box-shadow: 0px 4px 17px rgba(171, 184, 225, 0.4);
	border-radius: 10px;
}
.block-check .dropList p {
	font-weight: 700;
	font-size: 14px;
	line-height: 18px;
	margin-bottom: 12px;
	color: #a8b1ce;
}
.block-check .dropList p span {
	text-transform: uppercase;
}

.block-check .dropList li {
	margin-bottom: 12px;
	font-weight: 400;
	font-size: 14px;
	line-height: 25px;
	color: #6a6e83;
	display: flex;
	align-items: center;
}

.block-check .dropList li input {
	width: 20px;
	height: 20px;
	margin-right: 14px;
}
.table-respons td,
.table-respons th {
	padding: 20px 30px;
	text-align: left;
	vertical-align: center;
	border-bottom: 1px solid #f2f5ff;
	border-right: 1px solid #f2f5ff;
	position: relative;
}

.table-respons td:first-child {
	min-width: 300px;
	max-width: 300px;
}

th {
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
	color: #6a6e83;
}

td {
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	color: #6a6e83;
}

.table-respons {
	position: relative;
	overflow: auto;
	width: 100%;
	margin-top: 20px;
}

.table-respons table {
	width: 100%;
}

.table-respons .th-sort {
	text-decoration-line: underline;
	/* cursor: pointer; */
}

.bold td {
	font-weight: 600;
}

.table-respons {
	background: #fff;
	border-radius: 10px 10px 0 0;
	min-height: 385px;
	box-shadow: 0px 4px 17px rgba(171, 184, 225, 0.4);
}

.table-respons::-webkit-scrollbar {
	width: 11px; /* ширина всей полосы прокрутки */
}

.table-respons::-webkit-scrollbar-track {
	background: rgba(168, 177, 206, 0.3); /* цвет зоны отслеживания */
}

.table-respons::-webkit-scrollbar-thumb {
	background: #3377ff;
	border-radius: 10px;
}

.table-respons .filial {
	font-weight: 400;
	font-size: 13px;
	line-height: 20px;
	color: #fff;
	display: block;
	width: 110px;
	line-height: 28px;
	margin-bottom: 10px;
	text-align: center;
	background: #a8b1ce;
	border-radius: 4px;
}

.table-respons .active td,
.table-respons .filial-row td {
	border-bottom: 1px solid rgba(168, 177, 206, 0.2);
	border-right: 1px solid rgba(168, 177, 206, 0.2);
}

th.text-center {
	text-align: center;
}

.active {
	background: #f2f5ff;
}

.active .th-sort {
	color: #3377ff;
}

.filial-row {
	background: #f2f5ff;
}
</style>
