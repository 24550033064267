// Если нам надо оплучить глобальные данные
// Если нам надо обработать данные используем мутации
import { mutations } from './mutations';

export default {
	getResultForThreeYear(parametrs) {
		return new Promise((resolve, reject) => {
			this.$http
				.post('MedicalOrgs/GetResultForThreeYear', parametrs)
				.then((res) => {
					resolve(res);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	getRegionYearData(region) {
		return new Promise((resolve, reject) => {
			this.$http
				.get(`Region/GetRegionYearData?regionId=${region}`)
				.then((res) => {
					resolve(res);
					mutations.yearMutations(res.data.availableYears);
				})
				.catch((error) => {
					reject(error);
				});
		});
	}
};
